import React, { useState } from 'react';
import imageEdit from '../../images/edit.svg';
import imageTick from '../../images/tick.svg';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import * as actions from '../../redux/actions';
import { get } from 'lodash';

// const refill = ['Every Month', 'Every Two Month', 'Every Three Month'];

const Step4 = (props) => {
  const [paymentMethod, setPaymentMethod] = useState(0);
  const [personQuantity, setPersonQuantity] = useState(1);
  // const [chequeDetails, setChequeDetails] = useState({})

  const { maintain_profile, states } = useSelector((state) => state.users);
  const { medicine_list } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  const captureChange = (event) => {
    let paymentVia = { payment_via: event.target.id };
    dispatch(actions.update_profile_in_DB(paymentVia));
    setPaymentMethod(+event.target.value);
  };

  const redirect = () => {
    props.history.push('success');
  };

  const redirectToStripe = async () => {
    const stripe = await loadStripe(
      get(process.env, 'REACT_APP_STRIPE_PUB_KEY', '')
    );
    const resp = await dispatch(
      actions.get_stripe_session_id(
        personQuantity,
        maintain_profile?.add_medicine_for_me
      )
    );
    // When the customer clicks on the button, redirect them to Checkout.
    // const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: resp.data.id,
    });
    // If `redirectToCheckout` fails due to a browser or network
    // error, display the localized error message to your customer
    // using `error.message`.
  };

  // const backend_stripe_session = async() => {
  //     const resp = await dispatch(actions.get_stripe_session_id())
  //     console.log(resp)
  // }

  const submit = () => {
    if (paymentMethod === 1) {
      redirectToStripe();
    }
    if (paymentMethod === 2) {
      redirect();
    }
  };

  return (
    <div className="main-wrapper summary-wrapper container">
      <div className="row heading-wrapper">
        <div className="col-12">
          <h2
            className="main-heading"
            style={{ fontWeight: 'bold', fontSize: '36px' }}
          >
            Summary
          </h2>
          <p
            className="summary-intro section-black-text"
            style={{ fontSize: '22px' }}
          >
            <span>Whew! You did it!</span> Fantastic! This is your summary page.
            After you click "Submit" the rest is up to us!
          </p>
        </div>
      </div>
      <div className="row summary-details">
        <div className="col-10">
          <h5 style={{ color: '#022144' }}>
            {maintain_profile.first_name} {maintain_profile.last_name}
          </h5>
          <p
            className="summary-intro"
            style={{ fontSize: '22px', color: '#000' }}
          >
            {maintain_profile.address}
          </p>
          <p
            className="summary-intro"
            style={{ fontSize: '22px', color: '#000' }}
          >
            {maintain_profile?.city_name},{' '}
            {
              states.find((state) => state?.id === maintain_profile?.state_id)
                ?.name
            }
            , {maintain_profile?.zip_code}
          </p>
          <p
            className="summary-intro"
            style={{ fontSize: '22px', color: '#000' }}
          >
            {maintain_profile.email}, {maintain_profile.phone}
          </p>
        </div>
        <div className="col-2 d-flex justify-content-end">
          <i className="edit" onClick={() => props.setSection(1)}>
            <img src={imageEdit} alt="edit" />
          </i>
        </div>
      </div>
      <div className="row summary-details">
        <div className="col-10">
          {medicine_list.length !== 0 ? (
            medicine_list?.map((medicine) => {
              return (
                <div key={medicine.id} className="row section-two-head">
                  <div className="col-md-4">
                    <h6 className="section-two-intro">
                      {medicine.medicine.name}
                    </h6>
                    <p className="section-two-intro small">
                      {medicine.medicine.mg_range}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="section-two-intro small">
                      {medicine.how_often_take}
                      {medicine.how_often_take === '1x' ||
                      medicine.how_often_take === '2x' ||
                      medicine.how_often_take === '3x'
                        ? ` Daily`
                        : ''}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p className="section-two-intro small">
                      {medicine.how_often_refill}
                    </p>
                    <p className="section-two-intro small">Refill</p>
                  </div>
                </div>
              );
            })
          ) : (
            <h5 style={{ color: '#022144' }}>
              You want Help My Medicare to add medicines for you. We'll contact
              you soon.
            </h5>
          )}
          <div className="row d-none">
            <div className="col-12">
              <p className="summary-intro section-two-bottom section-black-text">
                <strong>Note:</strong> {'<Content goes here...>'}
              </p>
            </div>
          </div>
        </div>

        <div className="col-2 d-flex justify-content-end">
          <i className="edit" onClick={() => props.setSection(2)}>
            <img src={imageEdit} alt="edit" />
          </i>
        </div>
      </div>
      <div className="summary-details row">
        <div className="col-12" style={{ marginBottom: '20px' }}>
          <div className="row">
            <div className="col-md-3">
              <h5
                className="highlited"
                style={{ color: '#022144', marginLeft: 10 }}
              >
                Total ${maintain_profile?.add_medicine_for_me ? 300 : 250} per
                person
              </h5>
            </div>
            <div className="col-md-6">
              <Button
                // variant="light"
                style={{
                  width: 30,
                  height: 30,
                  padding: '5px 10px 5px 10px',
                  marginLeft: 20,
                  backgroundColor: '#dbdbd6',
                  borderColor: '#dbdbd6',
                }}
                onClick={() =>
                  personQuantity > 0
                    ? setPersonQuantity(personQuantity - 1)
                    : null
                }
              >
                {/* minus button */}
                <span> &#8722;</span>
              </Button>
              <input
                className="personQuantity"
                type="number"
                value={personQuantity}
              />
              <Button
                // variant="light"
                style={{
                  width: 30,
                  height: 30,
                  padding: '5px 5px 5px 5px',
                  marginLeft: 10,
                  backgroundColor: '#dbdbd6',
                  borderColor: '#dbdbd6',
                }}
                onClick={() => setPersonQuantity(personQuantity + 1)}
              >
                {/* plus button */}
                <span> &#65291;</span>
              </Button>
            </div>
          </div>
        </div>
        <div className="col-12">
          <Form className="col-md-12 " key={'refill'}>
            {/* <label className="drug-intro">How often do you refill this perscription?</label> */}
            <div className="row">
              <p className="summary-intro section-black-text">Payment via</p>
              <Form.Group className="col-md-3">
                <Form.Check
                  type="radio"
                  label="Pay online"
                  name="paymentMethod"
                  id="stripe"
                  value={1}
                  onChange={(e) => captureChange(e)}
                  checked={paymentMethod === 1}
                />
              </Form.Group>
              <Form.Group className="col-md-4">
                <Form.Check
                  type="radio"
                  label="Pay with a check"
                  name="paymentMethod"
                  id="check"
                  value={2}
                  onChange={(e) => captureChange(e)}
                  checked={paymentMethod === 2}
                />
                {paymentMethod === 2 && (
                  <div style={{ paddingTop: '1rem' }}>
                    <p>
                      If you pay by check, please make it payable to <br />
                      <span style={{ color: '#fe0000', fontSize: 20 }}>
                        Help My Medicare
                      </span>{' '}
                      and mail to
                      <br />
                      <strong style={{ fontSize: 20 }}>
                        Help My Medicare
                        <br />
                        8080 N. Central Expwy - Suite 1700
                        <br />
                        Dallas, TX 75206
                      </strong>
                    </p>
                  </div>
                )}
              </Form.Group>
              <Form.Group className="col-md-3">
                <Form.Check
                  type="radio"
                  label="I already paid for both of us"
                  name="paymentMethod"
                  onChange={() => props.history.push('/success')}
                />
              </Form.Group>
            </div>
          </Form>
        </div>
        {/* <div className="col-2 d-flex justify-content-end"><i className="edit"><img src={imageEdit} alt="edit" /></i></div> */}
      </div>

      <Button
        style={{ marginRight: 10, padding: '12px 25px', marginBottom: '10px' }}
        onClick={() => {
          window.scrollTo(0, 0);
          props.setSection(2);
        }}
      >
        Back
      </Button>

      <button
        style={{ marginBottom: '10px' }}
        className="btn btn-primary"
        disabled={paymentMethod === 0 || personQuantity === 0}
        type="button"
        onClick={() => submit()}
      >
        <i className="tick">
          <img src={imageTick} alt="delete" />
        </i>
        I’m Done. Proceed to Payment
      </button>
    </div>
  );
};

export default Step4;
